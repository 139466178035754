import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {
    console.log('Kuidvital Website');
    console.log('AppComponent ngOnInit');
  }

  ngOnDestroy(): void {
    console.log('AppComponent ngOnDestroy');
  }
}
