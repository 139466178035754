import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/modules/website/website.module').then(
        (m) => m.WebsiteModule
      ),
  },
  // {
  //   path: 'not-found',
  //   loadChildren: () =>
  //     import('src/app/modules/not-found/not-found.module').then(
  //       (m) => m.NotFoundModule
  //     ),
  // },
  // {
  //   path: '**',
  //   redirectTo: 'not-found',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
